import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import './App.css';

function App() {
  const [state, setState] = useState({
    series: [
      {
        name: 'Blood Pressure High',
        type: 'line',
        data: [120, 110, 125, 90, 105, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120, 120]
      },
      {
        name: 'Blood Pressure Low',
        type: 'line',
        data: [80, 60, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80]
      },
      {
        name: 'Temperature',
        type: 'column',
        data: [37.2, 36.5, 36.2, 36.8, 37.1, 38.2, 39.4, 40.1, 39.2, 38.7, 38, 37.9, 37.7, 37.5, 40.1, 39.2, 38.7, 38, 37.9, 37.7, 37.5]
      },
      {
        name: 'Pulse',
        type: 'column',
        data: [78, 75, 72, 68, 68, 69, 70, 72, 55, 60, 65, 72, 75, 77, 72, 55, 60, 65, 72, 75, 77]
      },
      {
        name: 'Resp. Rate',
        type: 'line',
        data: [16, 16, 17, 18, 19, 12, 15, 18, 14, 16, 16, 17, 20, 19, 18, 14, 16, 16, 17, 20, 19]
      },
      {
        name: 'O2 SAT',
        type: 'line',
        data: [98, 94, 92, 90, 88, 80, 95, 98, 92, 91, 98, 98, 93, 97, 98, 92, 91, 98, 98, 93, 97]
      },
      {
        name: 'Blood Sugar',
        type: 'line',
        data: [110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110]
      },
      {
        name: 'Pain Level',
        type: 'line',
        data: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4]
      },
      {
        name: 'GCS',
        type: 'line',
        data: [4, 5, 7, 2, 3, 5, 8, 9, 5, 5, 4, 6, 3, 7, 9, 5, 5, 4, 6, 3, 7]
      },
      {
        name: 'Triage',
        type: 'line',
        data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 2, 2, 2, 2, 2, 3, 3, 2]
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        stacked: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2]
      },
      title: {
        text: 'Patient Vitals and Monitoring',
        align: 'left'
      },
      xaxis: {
        categories: ["10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45", "03:00"]
      },
      yaxis: [
        {
          title: {
            text: 'Vitals (Left Y-Axis)'
          },
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#008FFB'
          },
          labels: {
            style: {
              colors: '#008FFB'
            },
          },
          tooltip: {
            enabled: true
          },
          seriesName: [ 'Pain Level', 'GCS', 'Triage','Resp. Rate']
        },
        {
          opposite: true,
          title: {
            text: 'Vitals (Right Y-Axis)'
          },
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#00E396'
          },
          labels: {
            style: {
              colors: '#00E396'
            }
          },
          seriesName: ['Blood Pressure High', 'Blood Pressure Low', 'Pulse', 'Temperature', 'O2 SAT', 'Blood Sugar',]
        }
      ],
      tooltip: {
        shared: true,
        intersect: false
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40
      }
    }
  });

  return (
    <div className="App">
      <ReactApexChart options={state.options} series={state.series} type="line" height={350} />
    </div>
  );
}

export default App;
